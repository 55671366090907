export default {
  sections: object => {
    return [
      {
        editable: true,
        title: 'АДРЕС',
        params: [
          {
            title: '',
            key: 'address_district_id'
          },
          {
            title: 'ПОЯСНЕНИЯ К АДРЕСУ',
            key: 'address_info'
          },
          {
            map: true,
            title: 'НА КАРТЕ',
            key: 'geo_polygon'
          }
        ]
      },
      {
        editable: true,
        title: 'Общие сведения',
        params: [
          {
            title: 'СПЕЦИАЛИЗАЦИЯ ОБЪЕКТА',
            key: 'object_specialization_name'
          },
          {
            title: 'КАДАСТРОВЫЙ НОМЕР ЗЕМЕЛЬНОГО УЧАСТКА',
            key: 'place_cadastral_number'
          },
          {
            title: 'ВИД РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ',
            key: 'permitted_use_kind_name'
          },
          {
            title: 'ВИД РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ, ОПИСАНИЕ',
            key: 'permitted_use_kind_description'
          },
          {
            title: 'ТИП ОБЪЕКТА И ВИД ОБЪЕКТА',
            key: 'object_type_name'
          },
          {
            title: 'ДОПОЛНИТЕЛЬНОЕ ОПИСАНИЕ ТИПА ОБЪЕКТА И ВИДА ОБЪЕКТА',
            key: 'object_type_description'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_nto_private_place_permitted_use'
          }
        ]
      },

      {
        title: 'ВЛАДЕЛЕЦ ОБЪЕКТА',
        params: [
          {
            title: 'ИНН',
            key: 'owner_object_inn'
          },
          {
            title: 'НАИМЕНОВАНИЕ',
            key: 'owner_object_name'
          },
          {
            title: 'СТАТУС',
            key: 'owner_object_status_name'
          },
          {
            _if: object.status_id === 10,
            title: 'ОРГАНИЗАЦИОННО-ПРАВОВАЯ ФОРМА',
            key: 'owner_object_opf_name'
          },
          {
            title: 'ДОЛЖНОСТНОЕ ЛИЦО ВЛАДЕЛЬЦА ОБЪЕКТА',
            key: 'owner_object_official'
          }
        ]
      },
      {
        title: 'ВЛАДЕЛЕЦ ЗЕМЕЛЬНОГО УЧАСТКА',
        params: [
          {
            title: 'ИНН',
            key: 'owner_place_inn'
          },
          {
            title: 'НАИМЕНОВАНИЕ',
            key: 'owner_place_name'
          },
          {
            title: 'СТАТУС',
            key: 'owner_place_status_name'
          },
          {
            _if: object.status_id === 10,
            title: 'ОРГАНИЗАЦИОННО-ПРАВОВАЯ ФОРМА',
            key: 'owner_place_opf_name'
          },
          {
            title: 'ДОЛЖНОСТНОЕ ЛИЦО ВЛАДЕЛЬЦА ЗЕМЕЛЬНОГО УЧАСТКА',
            key: 'owner_place_official'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_nto_private_place_confirming_ownership'
          }
        ]
      },
      {
        title:
          'РЕКВИЗИТЫ АКТА ПРОВЕРКИ, УСТАНАВЛИВАЮЩЕГО ФАКТ ВЫЯВЛЕНИЯ НЕЗАКОННОГО НТО',
        params: [
          {
            title: '№',
            key: 'audit_doc_number'
          },
          {
            title: 'ДАТА СОСТАВЛЕНИЯ',
            key: 'audit_doc_date'
          },
          {
            title: 'ДАТА ВЫЯВЛЕНИЯ',
            key: 'audit_doc_date'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_audit_doc_files'
          },
          {
            title: 'ФОТОГРАФИИ ОБЪЕКТА',
            key: 'file_audit_doc_photos'
          },
          {
            title:
              'ОТМЕТКА О ФУНКЦИОНИРОВАНИИ ТОРГОВОГО ОБЪЕКТА, ОБЪЕКТА ПО ОКАЗАНИЮ УСЛУГ (ФУНКЦИОНИРУЕТ)',
            key: 'object_functioning_mark_name'
          }
        ]
      },
      {
        editable: true,
        title: 'СОБЛЮДЕНИЕ ПРАВИЛ БЛАГОУСТРОЙСТВА',
        params: [
          {
            title: 'СОБЛЮДЕНИЕ ПРАВИЛ БЛАГОУСТРОЙСТВА',
            key: 'beautification_rules_name'
          },
          {
            title:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ ПО НЕ РАЗМЕЩЕНИЮ НТО ПРИВОДЯЩЕЕ К СОКРАЩЕНИЮ СВОБОДНОГО ДЛЯ ПЕШЕХОДНОГО ДВИЖЕНИЯ ТРОТУАРА ДО ШИРИНЫ МЕНЕЕ 3 МЕТРОВ',
            key: 'compliance_crosswalk_requirements'
          },
          {
            title:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ ПО НЕ РАЗМЕЩЕНИЮ НТО НА ПЛОЩАДКАХ (ДЕТСКИХ, ОТДЫХА, СПОРТИВНЫХ), НА ГАЗОНАХ, ЦВЕТНИКАХ, А ТАКЖЕ НА ОБЪЕКТАХ ОЗЕЛЕНЕНИЯ',
            key: 'compliance_fields_requirements'
          },
          {
            title:
              'СОБЛЮДЕНИЕ ВИДА РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ ЗЕМЕЛЬНОГО УЧАСТКА ПРИ РАЗМЕЩЕНИИ НТО',
            key: 'compliance_kind_requirements'
          },
          {
            title:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ ПО НЕ РАЗМЕЩЕНИЮ НТО НА ПРИДОМОВОЙ ТЕРРИТОРИИ',
            key: 'compliance_house_territory_requirements'
          },
          {
            title:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ О РАЗМЕЩЕНИИ НТО ДАЛЕЕ 15 МЕТРОВ ОТ ФАСАДОВ И ОКОН ЗДАНИЙ',
            key: 'compliance_facade_requirements'
          },
          {
            title:
              'СОБЛЮДЕНИЕ ТРЕБОВАНИЯ О НЕ РАЗМЕЩЕНИИ НТО В ГРАНИЦАХ ОБЪЕКТОВ КУЛЬТУРНОГО НАСЛЕДИЯ',
            key: 'compliance_cultural_heritage_requirements'
          }
        ]
      },
      {
        editable: true,
        title: 'СОБЛЮДЕНИЕ ПРАВИЛ БЛАГОУСТРОЙСТВА ПО «СТАРОЙ» ВЕРСИИ',
        params: [
          {
            title: 'СОБЛЮДЕНИЕ ПРАВИЛ БЛАГОУСТРОЙСТВА ПО «СТАРОЙ» ВЕРСИИ',
            key: 'beautification_rules_name_old'
          },
          {
            title:
              'РАСПОЛОЖЕН НА ТЕРРИТОРИИ БЛИЖЕ 15 МЕТРОВ ОТ ФАСАДОВ И ОКОН ЗДАНИЙ, ЗА ИСКЛЮЧЕНИЕМ СЛУЧАЕВ, УСТАНОВЛЕННЫХ ДЕЙСТВУЮЩИМ ЗАКОНОДАТЕЛЬСТВОМ',
            key: 'place_15m_to_facade'
          },
          {
            title:
              'РАСПОЛОЖЕН НА ЗЕМЕЛЬНЫХ УЧАСТКАХ, В ОТНОШЕНИИ КОТОРЫХ НЕ УСТАНОВЛЕН ВИД РАЗРЕШЕННОГО ИСПОЛЬЗОВАНИЯ, ДОПУСКАЮЩИЙ РАЗМЕЩЕНИЕ ТАКИХ ОБЪЕКТОВ, ЗА ИСКЛЮЧЕНИЕМ РАЗМЕЩЕНИЯ ТАКИХ ОБЪЕКТОВ НА ТЕРРИТОРИИ РОЗНИЧНЫХ РЫНКОВ, ЯРМАРОК, А ТАКЖЕ ПРИ ПРОВЕДЕНИИ МАССОВЫХ МЕРОПРИЯТИЙ',
            key: 'place_without_type_permitted_use'
          },
          {
            title:
              'РАСПОЛОЖЕН С НАРУШЕНИЕМ ТРЕБОВАНИЙ СТРОИТЕЛЬНЫХ, ЭКОЛОГИЧЕСКИХ, САНИТАРНО-ГИГИЕНИЧЕСКИХ, ПРОТИВОПОЖАРНЫХ И ИНЫХ ПРАВИЛ И НОРМАТИВОВ, УСТАНОВЛЕННЫХ ЗАКОНОДАТЕЛЬСТВОМ',
            key: 'place_with_violation_of_requirements'
          },
          {
            title: 'РАСПОЛОЖЕН НА ПРИДОМОВЫХ ТЕРРИТОРИЯХ',
            key: 'place_in_the_houses_area'
          },
          {
            title:
              'ПРЕПЯТСТВУЕТ ДОСТУПУ (ПРОХОДУ, ПРОЕЗДУ) С ТЕРРИТОРИИ ОБЩЕГО ПОЛЬЗОВАНИЯ К СМЕЖНЫМ ЗЕМЕЛЬНЫМ УЧАСТКАМ, К ЖИЛЫМ ПОМЕЩЕНИЯМ ИЛИ ОБЪЕКТАМ ТРАНСПОРТНОЙ ИЛИ СОЦИАЛЬНОЙ ИНФРАСТРУКТУРЫ',
            key: 'obstructs_passage'
          },
          {
            title:
              'РАСПОЛОЖЕН В АРКАХ ЗДАНИЙ, НА ПЛОЩАДКАХ (ДЕТСКИХ, ОТДЫХА, СПОРТИВНЫХ), НА СТОЯНКАХ ТРАНСПОРТНЫХ СРЕДСТВ',
            key: 'place_in_arches_of_buildings'
          },
          {
            title: 'РАСПОЛОЖЕН В ЗОНЕ ИНЖЕНЕРНЫХ СЕТЕЙ',
            key: 'place_in_area_of_engineering_networks'
          },
          {
            title: 'РАСПОЛОЖЕН В ГРАНИЦАХ ПОЛОСЫ ОТВОДА АВТОМОБИЛЬНОЙ ДОРОГИ',
            key: 'place_within_lane_of_road'
          }
        ]
      },

      {
        editable: true,
        title: 'ПРОЧЕЕ',
        params: [
          {
            title: 'МЕСТО ПОДКЛЮЧЕНИЯ К ЭЛЕКТРИЧЕСКИМ СЕТЯМ',
            key: 'place_electric_networks_name'
          },
          {
            title: 'МЕСТО ПОДКЛЮЧЕНИЯ К ЭЛЕКТРИЧЕСКИМ СЕТЯМ,АДРЕС ЗДАНИЯ',
            key: 'place_electric_networks_address'
          },
          {
            title: 'СТАТУС ПОДКЛЮЧЕНИЯ К ЭЛЕКТРИЧЕСКИМ СЕТЯМ',
            key: 'place_electric_networks_status_name'
          },
          {
            title:
              'СТАТУС НАПРАВЛЕНИЯ ПИСЬМА В ЭНЕРГОСНАБЖАЮЩУЮ ОРГАНИЗАЦИЮ О САМОВОЛЬНОМ ПОДКЛЮЧЕНИИ К ЭЛЕКТРИЧЕСКИМ СЕТЯМ',
            key: 'place_electric_networks_mail_status_name'
          },
          {
            title: 'ОТМЕТКА О РАЗМЕЩЕНИИ НА ПЕРВОЙ ЛИНИИ УЛИЦЫ',
            key: 'place_first_line_street_name'
          },
          {
            title:
              'СТАТУС НАПРАВЛЕНИЯ ПИСЬМА В ГОСПОЖНАДЗОР МЧС РОССИИ ПО ПЕРМСКОМУ КРАЮ',
            key: 'mcs_mail_status_name'
          },
          {
            title: 'МЕРОПРИЯТИЯ, ПРОВОДИМЫЕ МЧС РОССИИ ПО ПЕРМСКОМУ КРАЮ',
            key: 'mcs_actions_name'
          },
          {
            title: 'ДАТА ВЫДАЧА ПРЕДОСТЕРЕЖЕНИЯ ОТ МЧС',
            key: 'date_of_caution'
          },
          {
            title: 'ПРЕДОСТЕРЕЖЕНИЕ ОТ МЧС, КОПИИ ДОКУМЕНТОВ',
            files: true,
            key: 'file_nto_private_place_warning'
          },
          {
            title: 'ДАТА ПРЕДОСТАВЛЕНИЯ МАТЕРИАЛОВ НА ЗАСЕДАНИИ ПО ЧС',
            key: 'meeting_submission_date'
          },
          {
            title: 'МАТЕРИАЛЫ НА ЗАСЕДАНИИ ПО ЧС, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_materials_emergency_meeting',
            files: true
          }
        ]
      },
      {
        editable: true,
        title: 'Добровольный демонтаж',
        params: [
          {
            title: 'ДЕМОНТИРОВАН',
            key: 'mark_dismantling'
          },
          {
            title: 'ДАТА ДЕМОНТАЖА',
            key: 'dismantling_date'
          },
          {
            title: 'ПРИМЕЧАНИЕ',
            key: 'description'
          },
          {
            files: true,
            title: 'ДОКУМЕНТЫ',
            key: 'file_nto_private_place_voluntary_dismantling_docs'
          },
          {
            title: 'ФОТОГРАФИИ ОБЪЕКТА',
            key: 'file_nto_private_place_voluntary_dismantling_photos'
          }
        ]
      },
      {
        editable: true,
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ (ПО РЕШЕНИЮ КЧС)',
        params: [
          {
            title: 'ДАТА РЕШЕНИЯ КЧС О ДЕМОНТАЖЕ ОБЪЕКТА',
            key: 'dismantling_decision_date'
          },
          {
            title: 'НОМЕР РЕШЕНИЯ КЧС О ДЕМОНТАЖЕ ОБЪЕКТА',
            key: 'dismantling_decision_number'
          },
          {
            files: true,
            title: 'РЕШЕНИЕ КЧС ДЕМОНТАЖЕ ОБЪЕКТОВ, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_coes_decisions'
          },
          {
            title: 'ДАТА УВЕДОМЛЕНИЯ О РЕШЕНИИ КЧС И ТРЕБОВАНИИ О ДЕМОНТАЖЕ',
            key: 'coes_notification_decision_dismatle_date'
          },
          {
            files: true,
            title: 'РЕШЕНИИ КЧС, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_decision_dismantle_objects'
          }
        ]
      },
      {
        editable: true,
        title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ (ПО РЕШЕНИЮ СУДЕБНЫХ ОРГАНОВ)',
        params: [
          {
            title: 'ДАТА ПОДАЧИ ИСКА НА ВЛАДЕЛЬЦА ОБЪЕКТА',
            key: 'date_claim_object_owner'
          },
          {
            files: true,
            title: 'ИСК НА ВЛАДЕЛЬЦА ОБЪЕКТ, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_claim_object_owner'
          },
          {
            title: 'ДАТА РЕШЕНИЯ СУДЕБНЫХ ОРГАНОВ',
            key: 'judicial_decision_date'
          },
          {
            title: 'ДАТА ОКОНЧАНИЯ СРОКА ОБЖАЛОВАНИЯ',
            key: 'appeal_deadline_date'
          },
          {
            files: true,
            title: 'РЕШЕНИЕ СУДЕБНЫХ ОРГАНОВ, КОПИИ ДОКУМЕНТОВ',
            key: 'file_nto_private_place_judicial_decision'
          },
          {
            title: 'ДАТА ОКОНЧАНИЯ СРОКА ПРИНУДИТЕЛЬНОГО ДЕМОНТАЖА',
            key: 'expiration_date'
          },
          {
            title: 'ДАТА РАЗМЕЩЕНИЯ УВЕДОМЛЕНИЯ НА НТО',
            key: 'nto_notification_date'
          },
          {
            photos: true,
            title: 'РАЗМЕЩЕНИЕ УВЕДОМЛЕНИЯ НА НТО, ФОТОГРАФИИ',
            key: 'file_nto_private_place_nto_notification'
          },
          {
            title: 'ОТМЕТКА О ПРИНУДИТЕЛЬНОМ ДЕМОНТАЖЕ',
            key: 'forced_dismantling_mark'
          },
          {
            title: 'ДАТА ПРИНУДИТЕЛЬНОГО ДЕМОНТАЖА',
            key: 'forced_dismantling_date'
          },
          {
            title: 'ПРИНУДИТЕЛЬНЫЙ ДЕМОНТАЖ, ФОТОГРАФИИ',
            key: 'file_nto_private_place_forced_dismantling_photos'
          }
        ]
      }
      //{ title: "", params: [{ title: "", key: "" }] },
    ];
  }
};
